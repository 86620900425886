<template lang="pug">
  .quantity-input(:class="{ 'is-cart': isCart, 'updating-cart': updatingCart, 'loading': loading }")
    button.secondary(:class="{ 'disabled': disabled }" v-if="isCart" @click.prevent="changeValue(internalValue - 1)") -
    .required
      //- label(for="quantity" v-if="!isCart") Qty
      input(
        v-model.number="internalValue"
        type="number"
        id="quantity"
        name="quantity"
        :class="{ 'disabled': disabled }"
        min=`${ isCart ? 0 : 1 }`
        required
      )
    .button-wrapper
      button.secondary(:class="{ 'disabled': disabled }" @click.prevent="changeValue(internalValue + 1)") +
      button.secondary(:class="{ 'disabled': disabled }" v-if="!isCart" @click.prevent="changeValue(internalValue - 1)") -
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number],
      default: 1
    },
    isCart: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalValue: {
      get () { return this.value },
      set (v) {
        this.changeValue(v)
      }
    },

    ...mapState('cart', [
      'updatingCart'
    ])
  },

  methods: {
    changeValue (newVal) {
      if ((!this.isCart && newVal === 0) || newVal === '') {
        newVal = 1
      }

      this.$emit('input', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/_variables.scss';

.quantity-input {
  display: flex;
  align-items: flex-end;
  flex-basis: 30%;
  height: 45px !important;

  &.updating-cart, &.updating-cart > * {
    pointer-events: none;
    user-select: none;
  }

  &.loading {
    .required {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($white, .5);
      }

      &:after {
        content: '';
        position: absolute;
        right: 35%;
        top: 27%;
        width: 10px;
        height: 10px;
        margin: 0 auto;
        transform: rotate(0deg);
        animation: spinner .6s infinite linear;
        border: 2px solid transparent;
        border-top: 2px solid $black;
        border-radius: 50%;
        stroke: transparent;
      }
    }
  }

  .button-wrapper {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    height: 100%;
  }

  button {
    padding: 0 !important;
    width: 35px;
    background: $white;
    border-radius: 0;
    min-height: 20px;
    margin: 0;
    overflow: hidden;

    &:not(.disabled) {
      color: $black !important;
    }

    &, &:hover {
      border: 1px solid $gray;
      color: $gray;
    }

    &:first-of-type {
      border-bottom: 0;
    }

    &:last-of-type {
      border-top: 0;
    }
  }

  .required {
    align-self: flex-start;
    width: 100%;

    input {
      width: 100% !important;
      margin: 0 !important;
      font-size: 1em !important;
      border-radius: 0 !important;
      border-right: 0 !important;
      height: 100%;
      text-align: center;
      border: 1px solid $gray;
      color: $black;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button,
      &[type=number] {
        -moz-appearance: textfield;
        -webkit-appearance: none;
        margin: 0;
      }
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    p {
      white-space: nowrap;
    }
  }

  &.is-cart {
    align-items: flex-start;
    margin-bottom: 0.5em;

    input {
      width: auto!important;
      padding: 6px !important;
      max-width: 50px;
      border-left: 0 !important;
      border-color: $gray;
    }

    button {
      padding: 0px 7.5px !important;
      line-height: 1.6 !important;
      width: 30px !important;
      min-width: 30px !important;

      &:first-of-type {
        border-bottom: 1px solid $gray;
      }

      &:last-of-type {
        border-top: 1px solid $gray;
      }
    }

    input, button {
      height: 33px !important; //for iOS
    }
  }
}
</style>
